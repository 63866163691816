import React, { useEffect, useState } from 'react';
/************ hooks ************/
import useDateFormatter from 'hooks/formatDateWithPattern';
/************ External Library ************/
import { FormikProps, FormikValues } from 'formik';
/************ Styles ************/
// import "./TextInputs.scss";
import ImagePicker from 'Components/ObservationProfile/ImagePicker';
import { snakeToCamel } from 'utils/caseConvertor';
import {
  Dropdown,
  MultiselectDropdown,
  TextField,
  RadioButtonGroup,
  Checkboxes,
  FileUpload,
} from '../Components';
import { conditionalityForObservations } from 'utils/conditionalityForObservations';
import {
  DrawPolygon,
  DropPin,
  PointPicker,
  ShapePicker,
  GetGpsData,
} from '../MapComponents';
import questionTypes from 'view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes';
import GooglePlacesAutocomplete from '../Components/LocationInput';
import AudioRecorderComponent from '../Components/Audio';


/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */
interface DynamicComponentProps extends FormikProps<FormikValues> {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  error?: any;
  handleUpdateStatus: any;
  setObservationProfileData: any;
  observationProfileData: any;
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({
  panel,
  editMode,
  formikProps,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  error,
  values,
  handleUpdateStatus,
  setObservationProfileData,
  observationProfileData,
  ...restProps
}) => {

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name ?? '');

  const { formatDate } = useDateFormatter();

  const onChange = (eleName: string, value: any, name: string) => {
    
    // Update the Formik field value
    setFieldValue(eleName, value);

    // Additionally, update the observationProfileData with the new value for the element
    // const updatedSections = observationProfileData.sections.map(
    //   (section: any) => {
    //     if (section.sectionId === panel.sectionId) {
    //       // Map through the templateElements and update the element with the new value
    //       const updatedTemplateElements = section.templateElements.map(
    //         (element: any) => {
    //           // Construct the full element name to check against the changed element
    //           const fullElementName = [
    //             snakeToCamel(panel.name),
    //             element.groupQuestionName || element.name,
    //           ].join('.');
    //           if (fullElementName === eleName) {
    //             return { ...element, isUpdated: true, answer: value };
    //           }
    //           return element;
    //         }
    //       );

    //       // Return the section with updated template elements
    //       return { ...section, templateElements: updatedTemplateElements };
    //     }
    //     return section;
    //   }
    // );

    // // Set the updated sections back into the observation profile data state
    // setObservationProfileData((prevState: any) => ({
    //   ...prevState,
    //   sections: updatedSections,
    // }));

    // Optionally, trigger any additional status update logic
    handleUpdateStatus(name, panel.sectionId);
  };

  const duplicateElements = () => {
    const updatedSections = observationProfileData.sections.map(
      (section: any) => {
        if (section.sectionId === panel.sectionId) {
          // Collect all existing group indices in the section to ensure no conflicts
          const existingGroupIndices = new Set(
            section.templateElements.map((element: any) => element.groupIndex)
          );

          let lastElementIndex = section.templateElements.length - 1;
          const lastElement = section.templateElements[lastElementIndex];
          let lastGroupIndex = lastElement.groupIndex;

          const newIndexsxjckjscjkasfjklasd = lastGroupIndex + 1;
          const elementsToDuplicate = section.templateElements.filter(
            (element: any) => element.groupIndex === lastGroupIndex
          );

          const duplicatedPanelElements = elementsToDuplicate.map(
            (element: any, index: number) => {
              const newGroupQuestionName = `${element.name}${element.elementId}${panel.name}${newIndexsxjckjscjkasfjklasd}`;
              // Set the field value in Formik to null if it doesn't exist yet to avoid overwriting existing values
              if (
                formikProps.values[panelName]?.[newGroupQuestionName] ===
                undefined
              ) {
                formikProps.setFieldValue(
                  [panelName, newGroupQuestionName].join('.'),
                  null
                );
              }

              return {
                ...element,
                groupQuestionName: newGroupQuestionName,
                groupIndex: newIndexsxjckjscjkasfjklasd,
                questionIndex: index,
                answer: null, // Set answers to null to ensure duplicated elements start clean
              };
            }
          );

          // Append duplicated elements to the existing elements list
          return {
            ...section,
            templateElements: [
              ...section.templateElements,
              ...duplicatedPanelElements,
            ],
          };
        }
        return section; // Return other sections without modification
      }
    );

    // Update the state with the updated observationProfileData
    setObservationProfileData({
      ...observationProfileData,
      sections: updatedSections,
    });
    setIsLastGroupCleared(false);
  };

  // Remove Items Func
  const [isLastGroupCleared, setIsLastGroupCleared] = useState(false);

  const removeItems = (groupIndex: number) => {
    const totalGroups = new Set(
      observationProfileData.sections.flatMap((section: any) => {
        if (section.sectionId === panel.sectionId) {
          return section.templateElements.map((ele: any) => ele.groupIndex);
        }
        return []; // Ensure undefined is not returned in case the sectionId doesn't match
      })
    ).size;

    if (totalGroups > 1) {
      const updatedSections = observationProfileData.sections.map(
        (section: any) => {
          if (section.sectionId === panel.sectionId) {
            const filteredElements = section.templateElements.filter(
              (element: any) => element.groupIndex !== groupIndex
            );
            return {
              ...section,
              templateElements: filteredElements,
            };
          } else {
            return section;
          }
        }
      );

      setObservationProfileData((prevState: any) => ({
        ...prevState,
        sections: updatedSections,
      }));
    } else {
      // Clear values if only one group remains
      const updatedSections = observationProfileData.sections.map(
        (section: any) => {
          if (section.sectionId === panel.sectionId) {
            // Update elements' answer to null without removing them
            const updatedElements = section.templateElements.map(
              (element: any) => {
                if (element.groupIndex === groupIndex) {
                  formikProps.setFieldValue(
                    [panelName, element.groupQuestionName || element.name].join(
                      '.'
                    ),
                    null
                  ); // Also clear Formik values
                  return { ...element, answer: null }; // Set the answer to null
                }
                return element;
              }
            );
            return { ...section, templateElements: updatedElements };
          } else {
            return section;
          }
        }
      );

      setObservationProfileData((prevState: any) => ({
        ...prevState,
        sections: updatedSections,
      }));

      setIsLastGroupCleared(true); // Set flag to indicate that the last group has been cleared
    }
  };

  const renderRemoveButton = (element: any, index: number) => {
    const totalGroups = new Set(
      observationProfileData.sections.flatMap((section: any) => {
        if (section.sectionId === panel.sectionId) {
          return section.templateElements.map((ele: any) => ele.groupIndex);
        }
        return []; // Ensure undefined is not returned in case the sectionId doesn't match
      })
    ).size;

    const showRemoveButton = showRemoveButtonAtIndexes.has(index);
    // Button is enabled if there are more than one group or if the last group has not been cleared yet
    const isButtonEnabled = totalGroups > 1 || !isLastGroupCleared;

    return (
      showRemoveButton && (
        <div className='flex justify-end pb-2 mt-2 mb-4 border-b border-lineDark'>
          {editMode && (
            <button
              type='button'
              className={`text-accent_1Dark font-Overpass text-md transition ${!isButtonEnabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-accent_1'}`}
              onClick={() => removeItems(element.groupIndex)}
              disabled={!isButtonEnabled}
            >
              Remove Item
              {/* {totalGroups <= 1 && isLastGroupCleared ? "Values Cleared" : "Remove Item"} */}
            </button>
          )}
        </div>
      )
    );
  };

  const renderElement = (element: any, index: number) => {
    const {
      groupQuestionName,
      name,
      title,
      fieldAs,
      visible = true,
      isRequired = false,
      rows,
      value,
      inputType,
      type,
      placeholder,
      disabled,
      max,
      labelTrue,
      labelFalse,
      choices,
      choicesByUrl,
      visibleIf,
      enableIf,
      setValueIf,
      setValueExpression,
      requiredIf,
      id,
      select_multiple,
      selected_layers,
      selected_shapes,
      answer,
      description,
      defaultValue,
      multiSelect,
      colCount,
      maskType,
      maskSettings,
    } = element;

    const modifiedName = groupQuestionName || name;

    const eleName = [panelName, modifiedName].join('.');
    const error = formikProps.errors[panelName]?.[modifiedName];
    const touched = formikProps.touched[panelName]?.[modifiedName];

    if (visible === false || type === 'html' || defaultValue) {
      return;
    }

    switch (type) {
      case 'dropdown':
        return (
          <div key={index}>
            <Dropdown
              key={index}
              label={title || name}
              name={modifiedName}
              choicesByUrl={choicesByUrl}
              choices={choices}
              value={formikProps.values[panelName]?.[modifiedName]}
              handleChange={(item: any) =>
                onChange(eleName, item, modifiedName)
              }
              handleBlur={() =>
                formikProps.setFieldTouched(eleName, true, true)
              }
              editMode={editMode}
              placeholder={placeholder || 'Select'}
              required={isRequired}
              error={error}
              touched={touched}
              visibleIf={visibleIf}
              answers={formikProps.values[panelName]}
              enableIf={enableIf}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'tagbox':
        return (
          <div key={index}>
            <MultiselectDropdown
              name={modifiedName}
              data={choices}
              label={title || name}
              choicesByUrl={choicesByUrl}
              placeholder={placeholder || `Select ${title ?? ''}`}
              editMode={editMode}
              value={formikProps.values[panelName]?.[modifiedName] || []}
              handleChange={(selectedOptions: any) => {
                const selectedValues = selectedOptions.map(
                  (option: any) => option
                );
                onChange(eleName, selectedValues, modifiedName);
              }}
              handleBlur={() =>
                formikProps.setFieldTouched(eleName, true, true)
              }
              required={isRequired}
              error={error}
              touched={touched}
              visibleIf={visibleIf}
              answers={formikProps.values[panelName]}
              enableIf={enableIf}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'text':
        return (
          <TextField
            key={index}
            label={title || name}
            type={inputType || type}
            name={modifiedName}
            value={formikProps.values[panelName]?.[modifiedName]}
            handleChange={(value: any) =>
              onChange(eleName, value, modifiedName)
            }
            handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
            fieldAs={fieldAs}
            rows={rows}
            editMode={editMode}
            placeholder={value}
            required={isRequired}
            error={error}
            touched={touched}
            visibleIf={visibleIf}
            answers={formikProps.values[panelName]}
            enableIf={
              enableIf !== null
                ? conditionalityForObservations(
                    enableIf,
                    formikProps.values[panelName],
                    'enableIf'
                  )
                : false
            }
            setValueIf={setValueIf}
            setValueExpression={setValueExpression}
            requiredIf={requiredIf}
            maskType={maskType}
            maskSettings={maskSettings}
            panel={panel}
            element={element}
          />
        );

      case 'radiogroup':
        return (
          <div key={index}>
            <RadioButtonGroup
              key={index}
              label={title || name}
              name={eleName}
              editMode={editMode}
              value={formikProps.values[panelName]?.[modifiedName]}
              handleChange={(item: any) => {
                onChange(eleName, item, modifiedName);
              }}
              required={isRequired}
              data={choices}
              error={error}
              touched={touched}
              choicesByUrl={choicesByUrl}
              visibleIf={visibleIf}
              answers={formikProps.values[panelName]}
              enableIf={enableIf}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              columns={colCount}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'boolean':
        return (
          <div key={index}>
            <RadioButtonGroup
              key={index}
              label={title || name}
              name={eleName}
              editMode={editMode}
              value={formikProps.values[panelName]?.[modifiedName]}
              required={isRequired}
              handleChange={(item: any) =>
                onChange(eleName, item, modifiedName)
              }
              data={[labelTrue, labelFalse]}
              error={error}
              touched={touched}
              visibleIf={visibleIf}
              answers={formikProps.values[panelName]}
              enableIf={enableIf}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              columns={colCount}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'checkbox':
        return (
          <div key={index}>
            {/* <div className="relative grid items-center grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"> */}
            <Checkboxes
              key={index}
              data={choices}
              choicesByUrl={choicesByUrl}
              handleChange={(item: any) => {
                onChange(eleName, item, modifiedName);
              }}
              value={formikProps.values[panelName]?.[modifiedName]}
              name={eleName}
              label={title || name}
              required={isRequired}
              editMode={editMode}
              error={error}
              touched={touched}
              visibleIf={visibleIf}
              enableIf={enableIf}
              answers={formikProps.values[panelName]}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              columns={colCount}
              panel={panel}
              element={element}
            />
          </div>
          // </div>
        );

      case 'comment':
        return (
          <div className='col-span-12'>
            <TextField
              key={index}
              label={title || name}
              type='text'
              name={eleName}
              value={formikProps.values[panelName]?.[modifiedName]}
              handleChange={(value: any) => {
                onChange(eleName, value, modifiedName);
              }}
              handleBlur={() =>
                formikProps.setFieldTouched(eleName, true, true)
              }
              fieldAs='textarea'
              rows={rows}
              editMode={editMode}
              placeholder={placeholder || 'Type'}
              required={isRequired}
              error={error}
              touched={touched}
              visibleIf={visibleIf}
              answers={formikProps.values[panelName]}
              enableIf={enableIf}
              setValueIf={setValueIf}
              setValueExpression={setValueExpression}
              requiredIf={requiredIf}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'geocoder':
        return (
          <div className='col-span-12'>
            <GooglePlacesAutocomplete
              label={title || name}
              error={errors?.address}
              editMode={editMode}
              onBlur={handleBlur}
              placeholder='Geocode'
              handleAddressSelect={(address: any) => {
                onChange(eleName, address, modifiedName);
              }}
              AddressValue={formikProps.values[panelName]?.[modifiedName]}
              handleLatitudeAndLongitude={(longitude: any, latitude: any) => {
                // setFieldValue("location.lat", latitude);
                // setFieldValue("location.long", longitude);
              }}
              required={isRequired}
              name={eleName}
              panel={panel}
              element={element}
            />
          </div>
        );

      case 'audio':
        return (
          <div className='col-span-12'>
            <AudioRecorderComponent
              deleteAudio={() => {
                onChange(eleName, { url: '', transcription: '' }, modifiedName);
              }}
              setAudio={(audioSrc: any) => {
                onChange(eleName, audioSrc, modifiedName);
              }}
              editMode={editMode}
              label={title || name}
              required={isRequired}
              error={error}
              panel={panel}
              element={element}
              placeholder={placeholder}
              key={index}
              name={eleName}
              value={formikProps.values[panelName]?.[modifiedName]}
              formikProps={formikProps}
              touched={touched}
            />
          </div>
        );

      case 'imagepicker':
        return (
          <ImagePicker
            choices={choices}
            value={formikProps.values[panelName]?.[modifiedName]}
            label={title || name}
            name={eleName}
            editMode={editMode}
            handleClick={(link) => {
              if (editMode) {
                onChange(eleName, link, modifiedName);
              }
            }}
            required={isRequired}
            error={error}
            touched={touched}
            visibleIf={visibleIf}
            answers={formikProps.values[panelName]}
            enableIf={enableIf}
            setValueIf={setValueIf}
            setValueExpression={setValueExpression}
            requiredIf={requiredIf}
            multiSelect={multiSelect}
            panel={panel}
            element={element}
          />
        );
      case 'file':
        return (
          <FileUpload
            choices={choices}
            files={formikProps.values[panelName]?.[modifiedName]}
            choicesByUrl={choicesByUrl}
            name={eleName}
            editMode={editMode}
            handleUploadFile={(file: File[]) =>
              onChange(eleName, file, modifiedName)
            }
            required={isRequired}
            error={error}
            touched={touched}
            visibleIf={visibleIf}
            enableIf={enableIf}
            setValueIf={setValueIf}
            setValueExpression={setValueExpression}
            requiredIf={requiredIf}
            acceptedTypes={null}
            label={title}
            panel={panel}
            element={element}
          />
        );

      case questionTypes.shapePicker:
        return (
          <ShapePicker
            editMode={editMode}
            eleName={eleName}
            answer={formikProps.values[panelName]?.[modifiedName]}
            element={element}
            panel={panel}
            setFieldValue={(name: string, value: any) =>
              onChange(eleName, value, modifiedName)
            }
            required={isRequired}
            error={error}
            touched={touched}
          />
        );
      case questionTypes.pointPicker:
        return (
          <PointPicker
            editMode={editMode}
            eleName={eleName}
            panel={panel}
            answer={formikProps.values[panelName]?.[modifiedName]}
            element={element}
            setFieldValue={(name: string, value: any) =>
              onChange(eleName, value, modifiedName)
            }
            required={isRequired}
            error={error}
            touched={touched}
          />
        );

      case questionTypes.drawPolygon:
        return (
          <DrawPolygon
            editMode={editMode}
            eleName={eleName}
            panel={panel}
            answer={formikProps.values[panelName]?.[modifiedName]}
            element={element}
            setFieldValue={(name: string, value: any) =>
              onChange(eleName, value, modifiedName)
            }
            label={title || name}
            required={isRequired}
            error={error}
            touched={touched}
          />
        );

      case questionTypes.dropPin:
        return (
          <DropPin
            editMode={editMode}
            eleName={eleName}
            panel={panel}
            answer={formikProps.values[panelName]?.[modifiedName]}
            element={element}
            setFieldValue={(name: string, value: any) =>
              onChange(eleName, value, modifiedName)
            }
            label={title || name}
            required={isRequired}
            error={error}
            touched={touched}
          />
        );
      case questionTypes.getGpsData:
        return (
          <GetGpsData
            formikProps={formikProps}
            editMode={editMode}
            eleName={eleName}
            panel={panel}
            answer={formikProps.values[panelName]?.[modifiedName]}
            element={element}
            setFieldValue={(name: string, value: any) =>
              onChange(eleName, value, name)
            }
            label={title || name}
            required={isRequired}
            error={error}
            touched={touched}
          />
        );
      default:
        return (
          <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
            <p className='w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-textAnswer dark:text-textMain '>
              {type === 'date'
                ? formatDate(value === undefined ? ' ' : value)
                : value}
            </p>
          </div>
        );
    }
  };

  // This array will track the positions to show the "Remove Item" button
  const showRemoveButtonAtIndexes = new Set<number>();

  // Track the last index of each groupIndex to know where to place the "Remove Item" button
  let lastIndexBygroupIndex: { [key: number]: number } = {};
  panelElements.forEach((element: any, index: any) => {
    lastIndexBygroupIndex[element.groupIndex] = index;
  });

  // Add last indexes to the set
  Object.values(lastIndexBygroupIndex).forEach((index) => {
    showRemoveButtonAtIndexes.add(index);
  });

  return (
    <div className='flex flex-col flex-grow w-full mb-5 rounded-lg dark:bg-secondaryLight'>
      <div className='relative flex flex-col gap-4 py-2'>
        {panelElements.length > 0 &&
          panelElements.map((element: any, index: number) => {
            // if (!element.groupIndex) {
            //   element.groupIndex = 0;
            //   element.questionIndex = index;
            // }
            return (
              <React.Fragment key={index}>
                {renderElement(element, index)}
                {renderRemoveButton(element, index)}
              </React.Fragment>
            );
          })}
      </div>
      {editMode && (
        <div className='flex'>
          <button
            type='button'
            className='transition text-primary hover:text-primaryMid font-Overpass text-md'
            onClick={duplicateElements}
          >
            Add Item
          </button>
        </div>
      )}
    </div>
  );
};

export default DynamicComponent;
