import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IAuthResponseData } from 'store/auth/initialState';
import { permissionActions, permissionOperations } from './constants';

const usePermissions = () => {
  const auth = useSelector<RootState, IAuthResponseData>((state) => state.auth);
  const {
    qa,
    verifyEmailPhone,
    permissions,
    attachSocialAccount,
    users,
    types,
    newPassword,
    contactInformation,
    password,
    protocols,
    programs,
    logout,
    deleteAccountRequest,
    organizations,
    confirmEmailPhone,
    tags,
    dynamicForms,
    detachSocialAccount,
    dashboard,
    species,
    observations,
    download,
    file,
    directory,
    userQueries,
    universalSettings,
    accountInfo,
    roles,
    assignRoles,
    conversations,
    reports,
    objects,
    geographies,
    imports,
    categories,
    behaviors,
    conditions,
    universalSettingsImages,
    communities,
    profile,
    reminders,
    surveyAndPolls,
    notifications,
    trackings,
    globalSearch,
  } = auth?.permissions;

  const {
    qaActions,
    verifyEmailPhoneActions,
    permissionsActions,
    attachSocialAccountActions,
    usersActions,
    typesActions,
    newPasswordActions,
    contactInformationActions,
    passwordActions,
    protocolsActions,
    programsActions,
    logoutActions,
    deleteAccountRequestActions,
    organizationsActions,
    confirmEmailPhoneActions,
    tagsActions,
    dynamicFormsActions,
    detachSocialAccountActions,
    dashboardActions,
    speciesActions,
    observationsActions,
    downloadActions,
    fileActions,
    directoryActions,
    userQueriesActions,
    universalSettingsActions,
    accountInfoActions,
    rolesActions,
    assignRolesActions,
    conversationsActions,
    reportsActions,
    objectsActions,
    geographiesActions,
    importsActions,
    categoriesActions,
    behaviorsActions,
    universalSettingsImagesActions,
    communitiesActions,
    profileActions,
    remindersActions,
    notificationsActions,
    conditionsActions,
    surveyAndPollsActions,
    trackingsActions,
    globalSearchActions,
  } = permissionActions;

  const {
    qaOperations,
    verifyEmailPhoneOperations,
    permissionsOperations,
    attachSocialAccountOperations,
    usersOperations,
    typesOperations,
    newPasswordOperations,
    contactInformationOperations,
    passwordOperations,
    protocolsOperations,
    programsOperations,
    logoutOperations,
    deleteAccountRequestOperations,
    organizationsOperations,
    confirmEmailPhoneOperations,
    tagsOperations,
    dynamicFormsOperations,
    detachSocialAccountOperations,
    dashboardOperations,
    speciesOperations,
    observationsOperations,
    downloadOperations,
    fileOperations,
    directoryOperations,
    userQueriesOperations,
    universalSettingsOperations,
    accountInfoOperations,
    rolesOperations,
    assignRolesOperations,
    conversationsOperations,
    reportsOperations,
    objectsOperations,
    geographiesOperations,
    importsOperations,
    categoriesOperations,
    behaviorsOperations,
    conditionsOperations,
    universalSettingsImagesOperations,
    communitiesOperations,
    profileOperations,
    remindersOperations,
    surveyAndPollsOperations,
    notificationsOperations,
    trackingsOperations,
    globalSearchOperations,
  } = permissionOperations;

  const updatedPermissions = {
    qa: {
      canViewExternalReviewer: getAccessByActionAndOperation(
        qa,
        qaActions.externalReviewer,
        qaOperations.canView,
        'qa'
      ),
      canReview: getAccessByActionAndOperation(
        qa,
        qaActions.review,
        qaOperations.canEdit,
        'qa'
      ),
      reviewSection: getAccessByActionAndOperation(
        qa,
        qaActions.reviewSection,
        qaOperations.canEdit,
        'qa'
      ),
      canViewQa: getAccessByActionAndOperation(
        qa,
        qaActions.view,
        qaOperations.canView,
        'qa'
      ),
      canViewReviewerObservations: getAccessByActionAndOperation(
        qa,
        qaActions.reviewerObservation,
        qaOperations.canView,
        'qa'
      ),
    },
    verifyEmailPhone: {
      canCreateVerifyEmailPhone: getAccessByActionAndOperation(
        verifyEmailPhone,
        verifyEmailPhoneActions.create,
        verifyEmailPhoneOperations.canCreate,
        'verifyEmailPhone'
      ),
    },
    permissions: {
      canViewPermissions: getAccessByActionAndOperation(
        permissions,
        permissionsActions.view,
        permissionsOperations.canView,
        'permissions'
      ),
      canEditPermissions: getAccessByActionAndOperation(
        permissions,
        permissionsActions.edit,
        permissionsOperations.canEdit,
        'permissions'
      ),
      // canCreatePermissions: getAccessByActionAndOperation(
      //   permissions,
      //   permissionsActions.createAction,
      //   permissionsOperations.canCreate,
      //   "permissions"
      // ),
    },
    attachSocialAccount: {
      canCreateAttachSocialAccount: getAccessByActionAndOperation(
        attachSocialAccount,
        attachSocialAccountActions.create,
        attachSocialAccountOperations.canCreate,
        'attachSocialAccount'
      ),
    },
    users: {
      canViewBulkEmails: getAccessByActionAndOperation(
        users,
        usersActions.bulkEmails,
        usersOperations.canView
      ),
      canChangeStatus: getAccessByActionAndOperation(
        users,
        usersActions.changeStatus,
        usersOperations.canEdit,
        'users'
      ),
      canViewCommunityRoles: getAccessByActionAndOperation(
        users,
        usersActions.communitiesRole,
        usersOperations.canView
      ),
      canEditCommunityMembers: getAccessByActionAndOperation(
        users,
        usersActions.communityMember,
        usersOperations.canEdit
      ),
      canDelete: getAccessByActionAndOperation(
        users,
        usersActions.delete,
        usersOperations.canDelete
      ),
      canViewUsersInSideBar: getAccessByActionAndOperation(
        users,
        usersActions.view,
        usersOperations.canView,
        'users'
      ),
      canEditUsers: getAccessByActionAndOperation(
        users,
        usersActions.edit,
        usersOperations.canEdit,
        'users'
      ),
      canInviteUsers: getAccessByActionAndOperation(
        users,
        usersActions.invitation,
        usersOperations.canCreate,
        'users'
      ),
      canViewNotification: getAccessByActionAndOperation(
        users,
        usersActions.notification,
        usersOperations.canView,
        'users'
      ),
      canEditPersonProfile: getAccessByActionAndOperation(
        users,
        usersActions.person,
        usersOperations.canEdit,
        'users'
      ),
      canDeletePerson: getAccessByActionAndOperation(
        users,
        usersActions.person,
        usersOperations.canDelete,
        'users'
      ),
      canCreatePerson: getAccessByActionAndOperation(
        users,
        usersActions.person,
        usersOperations.canCreate,
        'users'
      ),
      canViewUsersProfile: getAccessByActionAndOperation(
        users,
        usersActions.profile,
        usersOperations.canView,
        'users'
      ),
      canResetPassword: getAccessByActionAndOperation(
        users,
        usersActions.resetPassword,
        usersOperations.canCreate,
        'users'
      ),
      canRestoreUsers: getAccessByActionAndOperation(
        users,
        usersActions.restore,
        usersOperations.canEdit,
        'users'
      ),
      canViewSettings: getAccessByActionAndOperation(
        users,
        usersActions.settings,
        usersOperations.canView,
        'users'
      ),
      canViewUsers: getAccessByActionAndOperation(
        users,
        usersActions.view,
        usersOperations.canView,
        'users'
      ),

      canViewDropdown: getAccessByActionAndOperation(
        users,
        usersActions.dropDown,
        usersOperations.canView
      ),

      canRemoveCommunityMember: getAccessByActionAndOperation(
        users,
        usersActions.communityMember,
        usersOperations.canDelete
      ),
    },

    types: {
      canArchive: getAccessByActionAndOperation(
        types,
        typesActions.archive,
        typesOperations.canArchive,
        'types'
      ),
      canCreateType: getAccessByActionAndOperation(
        types,
        typesActions.create,
        typesOperations.canCreate,
        'types'
      ),
      // canDeleteType: getAccessByActionAndOperation(
      //   types,
      //   typesActions.delete,
      //   typesOperations.canDelete,
      //   "types"
      // ),
      canViewDropdown: getAccessByActionAndOperation(
        types,
        typesActions.dropdown,
        typesOperations.canView,
        'types'
      ),
      canEditType: getAccessByActionAndOperation(
        types,
        typesActions.edit,
        typesOperations.canEdit,
        'types'
      ),
      canViewType: getAccessByActionAndOperation(
        types,
        typesActions.view,
        typesOperations.canView,
        'types'
      ),
    },
    newPassword: {
      canCreateNewPassword: getAccessByActionAndOperation(
        newPassword,
        newPasswordActions.create,
        newPasswordOperations.canCreate,
        'newPassword'
      ),
    },
    contactInformation: {
      canViewContactInformation: getAccessByActionAndOperation(
        contactInformation,
        contactInformationActions.view,
        contactInformationOperations.canView
      ),
      canEditContactInformation: getAccessByActionAndOperation(
        contactInformation,
        contactInformationActions.edit,
        contactInformationOperations.canEdit
      ),
      canCreateContactInformation: getAccessByActionAndOperation(
        contactInformation,
        contactInformationActions.create,
        contactInformationOperations.canCreate
      ),
      canDeleteContactInformation: getAccessByActionAndOperation(
        contactInformation,
        contactInformationActions.delete,
        contactInformationOperations.canDelete
      ),
    },
    passowrd: {
      canCreatePassword: getAccessByActionAndOperation(
        password,
        passwordActions.create,
        passwordOperations.canCreate
      ),
      canDeletePassword: getAccessByActionAndOperation(
        password,
        passwordActions.delete,
        passwordOperations.canDelete
      ),
      canEditPassword: getAccessByActionAndOperation(
        password,
        passwordActions.edit,
        passwordOperations.canEdit
      ),
      canViewPassword: getAccessByActionAndOperation(
        password,
        passwordActions.view,
        passwordOperations.canView
      ),
    },
    protocols: {
      canArchiveProtocol: getAccessByActionAndOperation(
        protocols,
        protocolsActions.archive,
        protocolsOperations.canArchive,
        'protocols'
      ),
      canConnectProgramForm: getAccessByActionAndOperation(
        protocols,
        protocolsActions.connectProgramForm,
        protocolsOperations.canEdit,
        'protocols'
      ),
      canCreateProtocol: getAccessByActionAndOperation(
        protocols,
        protocolsActions.create,
        protocolsOperations.canCreate,
        'protocols'
      ),
      canViewSideBarProtocol: getAccessByActionAndOperation(
        protocols,
        protocolsActions.directory,
        protocolsOperations.canView,
        'protocols'
      ),
      canDisconnectProgramForm: getAccessByActionAndOperation(
        protocols,
        protocolsActions.disconnectProgramForm,
        protocolsOperations.canEdit,
        'protocols'
      ),
      canDownloadProtocols: getAccessByActionAndOperation(
        protocols,
        protocolsActions.download,
        protocolsOperations.canView,
        'protocols'
      ),
      canEditProtocol: getAccessByActionAndOperation(
        protocols,
        protocolsActions.edit,
        protocolsOperations.canEdit,
        'protocols'
      ),
      canViewProtocol: getAccessByActionAndOperation(
        protocols,
        protocolsActions.view,
        protocolsOperations.canView,
        'protocols'
      ),
    },
    programs: {
      canViewAdminsDropdown: getAccessByActionAndOperation(
        programs,
        programsActions.adminsDropdown,
        programsOperations.canView,
        'programs'
      ),
      canArchiveProgram: getAccessByActionAndOperation(
        programs,
        programsActions.archive,
        programsOperations.canArchive,
        'programs'
      ),
      canViewCommunities: getAccessByActionAndOperation(
        programs,
        programsActions.communities,
        programsOperations.canView,
        'programs'
      ),
      canCreateCommunity: getAccessByActionAndOperation(
        programs,
        programsActions.communities,
        programsOperations.canCreate,
        'programs'
      ),
      canViewContactInfo: getAccessByActionAndOperation(
        programs,
        programsActions.contactInfo,
        programsOperations.canView,
        'programs'
      ),
      canCreateContactInfo: getAccessByActionAndOperation(
        programs,
        programsActions.contactInfo,
        programsOperations.canCreate,
        'programs'
      ),
      canCreateProgram: getAccessByActionAndOperation(
        programs,
        programsActions.create,
        programsOperations.canCreate,
        'programs'
      ),
      canViewDataAccessPolicy: getAccessByActionAndOperation(
        programs,
        programsActions.dataAccessPolicy,
        programsOperations.canView,
        'programs'
      ),
      canEditDataAccessPolicy: getAccessByActionAndOperation(
        programs,
        programsActions.dataAccessPolicy,
        programsOperations.canEdit,
        'programs'
      ),
      canCreateDataAccessPolicy: getAccessByActionAndOperation(
        programs,
        programsActions.dataAccessPolicy,
        programsOperations.canCreate,
        'programs'
      ),
      canViewProgramDirectory: getAccessByActionAndOperation(
        programs,
        programsActions.directory,
        programsOperations.canView,
        'programs'
      ),
      canEditProgram: getAccessByActionAndOperation(
        programs,
        programsActions.edit,
        programsOperations.canEdit,
        'programs'
      ),
      canDeleteEntity: getAccessByActionAndOperation(
        programs,
        programsActions.entity,
        programsOperations.canDelete,
        'programs'
      ),
      canViewEntityDropdown: getAccessByActionAndOperation(
        programs,
        programsActions.entityDropdown,
        programsOperations.canView,
        'programs'
      ),
      canCreateExternalEndDataViewer: getAccessByActionAndOperation(
        programs,
        programsActions.externalEndDataViewer,
        programsOperations.canCreate,
        'programs'
      ),
      canViewExternalEndDataViewer: getAccessByActionAndOperation(
        programs,
        programsActions.externalEndDataViewers,
        programsOperations.canView,
        'programs'
      ),
      canCreateFiles: getAccessByActionAndOperation(
        programs,
        programsActions.files,
        programsOperations.canCreate,
        'programs'
      ),
      canViewForms: getAccessByActionAndOperation(
        programs,
        programsActions.forms,
        programsOperations.canView,
        'programs'
      ),
      canEditManager: getAccessByActionAndOperation(
        programs,
        programsActions.manager,
        programsOperations.canEdit,
        'programs'
      ),
      canDeleteManager: getAccessByActionAndOperation(
        programs,
        programsActions.manager,
        programsOperations.canDelete,
        'programs'
      ),
      canViewManagers: getAccessByActionAndOperation(
        programs,
        programsActions.managers,
        programsOperations.canView,
        'programs'
      ),
      canCreateManagers: getAccessByActionAndOperation(
        programs,
        programsActions.managers,
        programsOperations.canCreate,
        'programs'
      ),
      canViewManagersDropdown: getAccessByActionAndOperation(
        programs,
        programsActions.managersDropdown,
        programsOperations.canView,
        'programs'
      ),
      canCreateMember: getAccessByActionAndOperation(
        programs,
        programsActions.member,
        programsOperations.canCreate,
        'programs'
      ),
      canReinviteExternalEndDataViewer: getAccessByActionAndOperation(
        programs,
        programsActions.ReinviteExternalEndDataViewer,
        programsOperations.canCreate,
        'programs'
      ),
      canViewOrganizationsDropdown: getAccessByActionAndOperation(
        programs,
        programsActions.organizationsDropdown,
        programsOperations.canView,
        'programs'
      ),
      canViewReviewers: getAccessByActionAndOperation(
        programs,
        programsActions.reviewers,
        programsOperations.canView,
        'programs'
      ),
      canEditStatus: getAccessByActionAndOperation(
        programs,
        programsActions.status,
        programsOperations.canEdit,
        'programs'
      ),
      canViewStatusHistory: getAccessByActionAndOperation(
        programs,
        programsActions.statusHistory,
        programsOperations.canView,
        'programs'
      ),
      canEditTags: getAccessByActionAndOperation(
        programs,
        programsActions.tags,
        programsOperations.canEdit,
        'programs'
      ),
      canViewUserMembers: getAccessByActionAndOperation(
        programs,
        programsActions.userMembers,
        programsOperations.canView,
        'programs'
      ),
      canViewProgram: getAccessByActionAndOperation(
        programs,
        programsActions.view,
        programsOperations.canView,
        'programs'
      ),
      // canDeleteProgram: getAccessByActionAndOperation(
      //   programs,
      //   programsActions.delete,
      //   programsOperations.canDelete
      // ),

      // canDeleteMember: getAccessByActionAndOperation(
      //   programs,
      //   programsActions.member,
      //   programsOperations.canDelete
      // ),
      canCreateMembers: getAccessByActionAndOperation(
        programs,
        programsActions.members,
        programsOperations.canCreate,
        'programs'
      ),
      // canViewObserversDropdown: getAccessByActionAndOperation(
      //   programs,
      //   programsActions.observersDropdown,
      //   programsOperations.canView
      // ),

      canDeleteTags: getAccessByActionAndOperation(
        programs,
        programsActions.tags,
        programsOperations.canDelete
      ),
    },
    logout: {
      canViewLogout: getAccessByActionAndOperation(
        logout,
        logoutActions.view,
        logoutOperations.canView
      ),
    },
    deleteAccountRequest: {
      canDeleteAccountRequest: getAccessByActionAndOperation(
        deleteAccountRequest,
        deleteAccountRequestActions.delete,
        deleteAccountRequestOperations.canDelete,
        'deleteAccountRequest'
      ),
    },
    organizations: {
      canCreateAdmins: getAccessByActionAndOperation(
        organizations,
        organizationsActions.admins,
        organizationsOperations.canCreate,
        'organizations'
      ),
      canArchiveOrganization: getAccessByActionAndOperation(
        organizations,
        organizationsActions.archive,
        organizationsOperations.canArchive,
        'organizations'
      ),
      canViewCommunities: getAccessByActionAndOperation(
        organizations,
        organizationsActions.communities,
        organizationsOperations.canView,
        'organizations'
      ),
      canViewCommunitiesMembers: getAccessByActionAndOperation(
        organizations,
        organizationsActions.communitiesMembers,
        organizationsOperations.canView,
        'organizations'
      ),
      canViewContactInfo: getAccessByActionAndOperation(
        organizations,
        organizationsActions.contactInfo,
        organizationsOperations.canView,
        'organizations'
      ),
      canCreateContactInfo: getAccessByActionAndOperation(
        organizations,
        organizationsActions.contactInfo,
        organizationsOperations.canCreate,
        'organizations'
      ),
      canCreateOrganization: getAccessByActionAndOperation(
        organizations,
        organizationsActions.create,
        organizationsOperations.canCreate,
        'organizations'
      ),
      // canDeleteOrganization: getAccessByActionAndOperation(
      //   organizations,
      //   organizationsActions.delete,
      //   organizationsOperations.canDelete
      // ),
      canViewOrganizationDirectory: getAccessByActionAndOperation(
        organizations,
        organizationsActions.directory,
        organizationsOperations.canView,
        'organizations'
      ),
      canEditOrganization: getAccessByActionAndOperation(
        organizations,
        organizationsActions.edit,
        organizationsOperations.canEdit,
        'organizations'
      ),
      canDeleteEntity: getAccessByActionAndOperation(
        organizations,
        organizationsActions.entity,
        organizationsOperations.canDelete,
        'organizations'
      ),
      canViewOrganization: getAccessByActionAndOperation(
        organizations,
        organizationsActions.view,
        organizationsOperations.canView,
        'organizations'
      ),

      // canEditTags: getAccessByActionAndOperation(
      //   organizations,
      //   organizationsActions.tags,
      //   organizationsOperations.canEdit
      // ),
      // canDeleteTags: getAccessByActionAndOperation(
      //   organizations,
      //   organizationsActions.tags,
      //   organizationsOperations.canDelete
      // ),
    },
    confirmEmailPhone: {
      canCreateConfirmEmailPhone: getAccessByActionAndOperation(
        confirmEmailPhone,
        confirmEmailPhoneActions.create,
        confirmEmailPhoneOperations.canCreate,
        'confirmEmailPhone'
      ),
      canDeleteConfirmEmailPhone: getAccessByActionAndOperation(
        confirmEmailPhone,
        confirmEmailPhoneActions.delete,
        confirmEmailPhoneOperations.canDelete
      ),
      canEditConfirmEmailPhone: getAccessByActionAndOperation(
        confirmEmailPhone,
        confirmEmailPhoneActions.edit,
        confirmEmailPhoneOperations.canEdit
      ),
      canViewConfirmEmailPhone: getAccessByActionAndOperation(
        confirmEmailPhone,
        confirmEmailPhoneActions.view,
        confirmEmailPhoneOperations.canView
      ),
    },
    tags: {
      canCreateTag: getAccessByActionAndOperation(
        tags,
        tagsActions.create,
        tagsOperations.canCreate,
        'tags'
      ),
      canDeleteTag: getAccessByActionAndOperation(
        tags,
        tagsActions.delete,
        tagsOperations.canDelete,
        'tags'
      ),
      canViewDropdownTag: getAccessByActionAndOperation(
        tags,
        tagsActions.dropdown,
        tagsOperations.canView,
        'tags'
      ),
      canEditTag: getAccessByActionAndOperation(
        tags,
        tagsActions.edit,
        tagsOperations.canEdit,
        'tags'
      ),
      canViewTag: getAccessByActionAndOperation(
        tags,
        tagsActions.view,
        tagsOperations.canView,
        'tags'
      ),
    },
    dynamicForms: {
      canArchiveDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.archive,
        dynamicFormsOperations.canArchive,
        'dynamicForms'
      ),
      canCreateDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.create,
        dynamicFormsOperations.canCreate,
        'dynamicForms'
      ),
      canDeleteDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.delete,
        dynamicFormsOperations.canDelete,
        'dynamicForms'
      ),
      canEditDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.edit,
        dynamicFormsOperations.canEdit,
        'dynamicForms'
      ),
      canEditDynamicFormFields: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.fields,
        dynamicFormsOperations.canEdit,
        'dynamicForms'
      ),
      canViewDynamicFormGeographies: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.geographies,
        dynamicFormsOperations.canView,
        'dynamicForms'
      ),
      canEditDynamicFormStatus: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.status,
        dynamicFormsOperations.canEdit,
        'dynamicForms'
      ),
      canCreateDynamicFormTemplate: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.template,
        dynamicFormsOperations.canCreate,
        'dynamicForms'
      ),
      canViewDirectoryFromSideBarDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.directory,
        dynamicFormsOperations.canView,
        'dynamicForms'
      ),
      canViewDynamicForm: getAccessByActionAndOperation(
        dynamicForms,
        dynamicFormsActions.view,
        dynamicFormsOperations.canView,
        'dynamicForms'
      ),
    },
    detachSocialAccount: {
      canCreateDetachSocialAccount: getAccessByActionAndOperation(
        detachSocialAccount,
        detachSocialAccountActions.create,
        detachSocialAccountOperations.canCreate,
        'detachSocialAccount'
      ),
    },
    species: {
      canArchiveSpecies: getAccessByActionAndOperation(
        species,
        speciesActions.archive,
        speciesOperations.canArchive,
        'species'
      ),
      canCreateSpecies: getAccessByActionAndOperation(
        species,
        speciesActions.create,
        speciesOperations.canCreate,
        'species'
      ),
      // canDeleteSpecies: getAccessByActionAndOperation(
      //   species,
      //   speciesActions.delete,
      //   speciesOperations.canDelete,
      //   "species"
      // ),
      // canViewSpeciesDropdown: getAccessByActionAndOperation(
      //   species,
      //   speciesActions.dropdown,
      //   speciesOperations.canView,
      //   "species"
      // ),
      canEditSpecies: getAccessByActionAndOperation(
        species,
        speciesActions.edit,
        speciesOperations.canEdit,
        'species'
      ),
      // canDeleteSpeciesFile: getAccessByActionAndOperation(
      //   species,
      //   speciesActions.file,
      //   speciesOperations.canDelete,
      //   "species"
      // ),
      canViewSpecies: getAccessByActionAndOperation(
        species,
        speciesActions.view,
        speciesOperations.canView,
        'species'
      ),
    },
    dashboard: {
      canViewActiveUsers: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.activeUsers,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewClusteredMap: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.clusteredMap,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewDashboardConfiguration: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.configuration,
        dashboardOperations.canView,
        'dashboard'
      ),
      canCreateDashboardConfiguration: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.configuration,
        dashboardOperations.canCreate,
        'dashboard'
      ),
      canViewCumulativeObservations: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.cumulativeObservations,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewHorizontalBarGraph: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.horizontalBarGraph,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewKPIs: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.kpis,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewMostObservedObjects: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.mostObservedObjects,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewMostObservedSpecies: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.mostObservedSpecies,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewNotifications: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.notifications,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewObservations: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.observations,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewObservationsByRegion: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.observationsByRegion,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewQANotifications: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.qaNotifications,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewQAProgress: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.qaProgress,
        dashboardOperations.canView,
        'dashboard'
      ),
      canViewReportNotifications: getAccessByActionAndOperation(
        dashboard,
        dashboardActions.reportNotifications,
        dashboardOperations.canView,
        'dashboard'
      ),
      // canViewDashboard: getAccessByActionAndOperation(
      //   dashboard,
      //   dashboardActions.view,
      //   dashboardOperations.canView
      // ),
    },
    observations: {
      canViewActivities: getAccessByActionAndOperation(
        observations,
        observationsActions.activities,
        observationsOperations.canView,
        'observations'
      ),
      canArchiveObservation: getAccessByActionAndOperation(
        observations,
        observationsActions.archive,
        observationsOperations.canArchive,
        'observations'
      ),
      canEditObservationReviewer: getAccessByActionAndOperation(
        observations,
        observationsActions.attachReviewer,
        observationsOperations.canEdit,
        'observations'
      ),
      canEditObservationComment: getAccessByActionAndOperation(
        observations,
        observationsActions.comment,
        observationsOperations.canEdit,
        'observations'
      ),
      canEditObservationPropertyCopy: getAccessByActionAndOperation(
        observations,
        observationsActions.copyProperty,
        observationsOperations.canEdit,
        'observations'
      ),
      canEditObservation: getAccessByActionAndOperation(
        observations,
        observationsActions.edit,
        observationsOperations.canEdit,
        'observations'
      ),
      canCreateExternalReviewer: getAccessByActionAndOperation(
        observations,
        observationsActions.externalReviewer,
        observationsOperations.canCreate,
        'observations'
      ),
      canViewImportInfo: getAccessByActionAndOperation(
        observations,
        observationsActions.importInfo,
        observationsOperations.canView,
        'observations'
      ),
      canEditInviteExternalReviewer: getAccessByActionAndOperation(
        observations,
        observationsActions.inviteExternalReviewer,
        observationsOperations.canEdit,
        'observations'
      ),
      canCreateReinviteReviewer: getAccessByActionAndOperation(
        observations,
        observationsActions.reinviteReviewer,
        observationsOperations.canCreate,
        'observations'
      ),
      canEditRequestConsent: getAccessByActionAndOperation(
        observations,
        observationsActions.requestConsent,
        observationsOperations.canEdit,
        'observations'
      ),
      canEditReviewerAccessFor: getAccessByActionAndOperation(
        observations,
        observationsActions.reviewerAccessFor,
        observationsOperations.canEdit,
        'observations'
      ),
      canEditSeenActivities: getAccessByActionAndOperation(
        observations,
        observationsActions.seenActivities,
        observationsOperations.canEdit,
        'observations'
      ),
      canViewObservations: getAccessByActionAndOperation(
        observations,
        observationsActions.view,
        observationsOperations.canView,
        'observations'
      ),
      // canCreateObservation: getAccessByActionAndOperation(
      //   observations,
      //   observationsActions.create,
      //   observationsOperations.canCreate
      // ),
      // canDeleteObservation: getAccessByActionAndOperation(
      //   observations,
      //   observationsActions.delete,
      //   observationsOperations.canDelete
      // ),
      // canDeleteObservationManager: getAccessByActionAndOperation(
      //   observations,
      //   observationsActions.manager,
      //   observationsOperations.canDelete
      // ),
      // canCreateObservationManagers: getAccessByActionAndOperation(
      //   observations,
      //   observationsActions.managers,
      //   observationsOperations.canCreate
      // ),
      // canViewObservationManagersDropdown: getAccessByActionAndOperation(
      //   observations,
      //   observationsActions.managersDropdown,
      //   observationsOperations.canView
      // ),
    },
    download: {
      canCreateDownload: getAccessByActionAndOperation(
        download,
        downloadActions.create,
        downloadOperations.canCreate
      ),
    },
    file: {
      canCreateFile: getAccessByActionAndOperation(
        file,
        fileActions.create,
        fileOperations.canCreate,
        'file'
      ),
      canViewFile: getAccessByActionAndOperation(
        file,
        fileActions.view,
        fileOperations.canView,
        'file'
      ),
    },

    directory: {
      canCreateDirectory: getAccessByActionAndOperation(
        directory,
        directoryActions.create,
        directoryOperations.canCreate
      ),
      canDeleteDirectory: getAccessByActionAndOperation(
        directory,
        directoryActions.delete,
        directoryOperations.canDelete
      ),
      canEditDirectory: getAccessByActionAndOperation(
        directory,
        directoryActions.edit,
        directoryOperations.canEdit
      ),
      canCreateDirectorySave: getAccessByActionAndOperation(
        directory,
        directoryActions.save,
        directoryOperations.canCreate
      ),
      canViewSelectedDirectory: getAccessByActionAndOperation(
        directory,
        directoryActions.selected,
        directoryOperations.canView
      ),
      canViewDirectory: getAccessByActionAndOperation(
        directory,
        directoryActions.view,
        directoryOperations.canView
      ),
    },
    userQueries: {
      canEditUserQueriesAnswer: getAccessByActionAndOperation(
        userQueries,
        userQueriesActions.answer,
        userQueriesOperations.canEdit
      ),
      canViewUserQueries: getAccessByActionAndOperation(
        userQueries,
        userQueriesActions.view,
        userQueriesOperations.canView
      ),
    },
    universalSettings: {
      canEditUniversalSettings: getAccessByActionAndOperation(
        universalSettings,
        universalSettingsActions.edit,
        universalSettingsOperations.canEdit,
        'universal-settings'
      ),
      canViewUniversalSettings: getAccessByActionAndOperation(
        universalSettings,
        universalSettingsActions.view,
        universalSettingsOperations.canView,
        'universal-settings'
      ),
    },
    accountInfo: {
      canCreateAccountInfo: getAccessByActionAndOperation(
        accountInfo,
        accountInfoActions.create,
        accountInfoOperations.canCreate
      ),
      canDeleteAccountInfo: getAccessByActionAndOperation(
        accountInfo,
        accountInfoActions.delete,
        accountInfoOperations.canDelete
      ),
      canEditAccountInfo: getAccessByActionAndOperation(
        accountInfo,
        accountInfoActions.edit,
        accountInfoOperations.canEdit
      ),
      canViewAccountInfo: getAccessByActionAndOperation(
        accountInfo,
        accountInfoActions.view,
        accountInfoOperations.canView
      ),
    },
    roles: {
      canCreateRole: getAccessByActionAndOperation(
        roles,
        rolesActions.create,
        rolesOperations.canCreate,
        'roles'
      ),
      canDeleteRole: getAccessByActionAndOperation(
        roles,
        rolesActions.delete,
        rolesOperations.canDelete,
        'roles'
      ),
      canCreateDuplicateRole: getAccessByActionAndOperation(
        roles,
        rolesActions.duplicateRole,
        rolesOperations.canCreate,
        'roles'
      ),
      canEditRole: getAccessByActionAndOperation(
        roles,
        rolesActions.edit,
        rolesOperations.canEdit,
        'roles'
      ),
      canEditEditableRole: getAccessByActionAndOperation(
        roles,
        rolesActions.editable,
        rolesOperations.canEdit,
        'roles'
      ),
      canViewRole: getAccessByActionAndOperation(
        roles,
        rolesActions.view,
        rolesOperations.canView,
        'roles'
      ),
    },
    assignRoles: {
      canEditAssignRoles: getAccessByActionAndOperation(
        assignRoles,
        assignRolesActions.edit,
        assignRolesOperations.canEdit,
        'assignRoles'
      ),
    },
    conversations: {
      canArchiveConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.archive,
        conversationsOperations.canArchive,
        'conversations'
      ),
      canDeleteChatConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.chat,
        conversationsOperations.canDelete,
        'conversations'
      ),
      canViewContactPersons: getAccessByActionAndOperation(
        conversations,
        conversationsActions.contactPersons,
        conversationsOperations.canView,
        'conversations'
      ),
      canCreateConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.create,
        conversationsOperations.canCreate,
        'conversations'
      ),
      canDeleteConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.delete,
        conversationsOperations.canDelete,
        'conversations'
      ),
      canViewExportConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.export,
        conversationsOperations.canView,
        'conversations'
      ),
      canCreateMessageConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.message,
        conversationsOperations.canCreate,
        'conversations'
      ),
      canEditMuteUnmuteConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.muteUnmute,
        conversationsOperations.canEdit,
        'conversations'
      ),
      canViewConversations: getAccessByActionAndOperation(
        conversations,
        conversationsActions.view,
        conversationsOperations.canView,
        'conversations'
      ),
    },
    reports: {
      canCreateAddPermissionReports: getAccessByActionAndOperation(
        reports,
        reportsActions.addPermission,
        reportsOperations.canCreate,
        'reports'
      ),
      canViewAdminsReports: getAccessByActionAndOperation(
        reports,
        reportsActions.admins,
        reportsOperations.canView,
        'reports'
      ),
      canViewAnalyticsReports: getAccessByActionAndOperation(
        reports,
        reportsActions.analytics,
        reportsOperations.canView,
        'reports'
      ),
      canViewAnalyticsDropdownReports: getAccessByActionAndOperation(
        reports,
        reportsActions.analyticsDropdown,
        reportsOperations.canView,
        'reports'
      ),
      canArchiveReports: getAccessByActionAndOperation(
        reports,
        reportsActions.archive,
        reportsOperations.canArchive,
        'reports'
      ),
      canCreateChooseColumnsReports: getAccessByActionAndOperation(
        reports,
        reportsActions.chooseColumns,
        reportsOperations.canCreate,
        'reports'
      ),
      canViewColumnsReports: getAccessByActionAndOperation(
        reports,
        reportsActions.columns,
        reportsOperations.canView,
        'reports'
      ),
      canViewGeoSpatialColumnsReports: getAccessByActionAndOperation(
        reports,
        reportsActions.geoSpatialColumn,
        reportsOperations.canView,
        'reports'
      ),
      canCreateReports: getAccessByActionAndOperation(
        reports,
        reportsActions.create,
        reportsOperations.canCreate,
        'reports'
      ),
      canDeleteReports: getAccessByActionAndOperation(
        reports,
        reportsActions.delete,
        reportsOperations.canDelete,
        'reports'
      ),
      canViewDownloadResultReports: getAccessByActionAndOperation(
        reports,
        reportsActions.downloadResult,
        reportsOperations.canView,
        'reports'
      ),
      canEditReports: getAccessByActionAndOperation(
        reports,
        reportsActions.edit,
        reportsOperations.canEdit,
        'reports'
      ),
      canViewEntityFiltersReports: getAccessByActionAndOperation(
        reports,
        reportsActions.entityFilters,
        reportsOperations.canView,
        'reports'
      ),
      canViewEntityDropDownReports: getAccessByActionAndOperation(
        reports,
        reportsActions.entityDrodown,
        reportsOperations.canView,
        'reports'
      ),
      canEditExportPermissionReports: getAccessByActionAndOperation(
        reports,
        reportsActions.exportPermission,
        reportsOperations.canEdit,
        'reports'
      ),
      canCreateFilteringReports: getAccessByActionAndOperation(
        reports,
        reportsActions.filtering,
        reportsOperations.canCreate,
        'reports'
      ),

      canCreateGeospatialFilteringReports: getAccessByActionAndOperation(
        reports,
        reportsActions.geospatialFiltering,
        reportsOperations.canCreate,
        'reports'
      ),
      canCreateManualVersionReports: getAccessByActionAndOperation(
        reports,
        reportsActions.manualVersion,
        reportsOperations.canCreate,
        'reports'
      ),
      canViewProcessedVersionReports: getAccessByActionAndOperation(
        reports,
        reportsActions.processedVersion,
        reportsOperations.canView,
        'reports'
      ),
      canViewResultReports: getAccessByActionAndOperation(
        reports,
        reportsActions.result,
        reportsOperations.canView,
        'reports'
      ),
      canCreateSettingReports: getAccessByActionAndOperation(
        reports,
        reportsActions.setting,
        reportsOperations.canCreate,
        'reports'
      ),
      canViewSummaryReports: getAccessByActionAndOperation(
        reports,
        reportsActions.summary,
        reportsOperations.canView,
        'reports'
      ),
      canDeleteVersionReports: getAccessByActionAndOperation(
        reports,
        reportsActions.version,
        reportsOperations.canDelete,
        'reports'
      ),
      canViewReports: getAccessByActionAndOperation(
        reports,
        reportsActions.view,
        reportsOperations.canView,
        'reports'
      ),
    },

    objects: {
      canArchiveObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.archive,
        objectsOperations.canArchive,
        'objects'
      ),
      canCreateObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.create,
        objectsOperations.canCreate,
        'objects'
      ),
      canViewDropdownObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.dropdown,
        objectsOperations.canView,
        'objects'
      ),
      canEditObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.edit,
        objectsOperations.canEdit,
        'objects'
      ),
      canDeleteFileObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.file,
        objectsOperations.canDelete,
        'objects'
      ),
      canViewObjects: getAccessByActionAndOperation(
        objects,
        objectsActions.view,
        objectsOperations.canView,
        'objects'
      ),
    },
    geographies: {
      canArchiveGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.archive,
        geographiesOperations.canArchive,
        'geographies'
      ),
      canCreateGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.create,
        geographiesOperations.canCreate,
        'geographies'
      ),
      canViewGeographiesInSideBar: getAccessByActionAndOperation(
        geographies,
        geographiesActions.directory,
        geographiesOperations.canView,
        'geographies'
      ),
      canEditGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.edit,
        geographiesOperations.canEdit,
        'geographies'
      ),
      canViewMapListGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.mapList,
        geographiesOperations.canView,
        'geographies'
      ),
      canViewVectorLayers: getAccessByActionAndOperation(
        geographies,
        geographiesActions.vectorLayer,
        geographiesOperations.canView,
        'geographies'
      ),
      canViewReportsGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.reportsGeographies,
        geographiesOperations.canView,
        'geographies'
      ),
      canViewGeographies: getAccessByActionAndOperation(
        geographies,
        geographiesActions.view,
        geographiesOperations.canView,
        'geographies'
      ),
    },
    imports: {
      canEditColumnMappingImports: getAccessByActionAndOperation(
        imports,
        importsActions.columnMapping,
        importsOperations.canEdit,
        'imports'
      ),
      canCreateImports: getAccessByActionAndOperation(
        imports,
        importsActions.create,
        importsOperations.canCreate,
        'imports'
      ),
      canCreateImportImports: getAccessByActionAndOperation(
        imports,
        importsActions.import,
        importsOperations.canCreate,
        'imports'
      ),
      canViewPropertyDropdownImports: getAccessByActionAndOperation(
        imports,
        importsActions.propertyDropdown,
        importsOperations.canView,
        'imports'
      ),

      canViewStatusDetailImports: getAccessByActionAndOperation(
        imports,
        importsActions.statusDetail,
        importsOperations.canView,
        'imports'
      ),
      canViewSummaryImports: getAccessByActionAndOperation(
        imports,
        importsActions.summary,
        importsOperations.canView,
        'imports'
      ),

      canEditObserverImports: getAccessByActionAndOperation(
        imports,
        importsActions.updateObserver,
        importsOperations.canEdit,
        'imports'
      ),
      canCreateUploadImports: getAccessByActionAndOperation(
        imports,
        importsActions.upload,
        importsOperations.canCreate,
        'imports'
      ),
      canViewImports: getAccessByActionAndOperation(
        imports,
        importsActions.view,
        importsOperations.canView,
        'imports'
      ),
      canViewViewDataImports: getAccessByActionAndOperation(
        imports,
        importsActions.viewData,
        importsOperations.canView,
        'imports'
      ),
    },
    categories: {
      canArchiveCategories: getAccessByActionAndOperation(
        categories,
        categoriesActions.archive,
        categoriesOperations.canArchive,
        'categories'
      ),
      canCreateCategories: getAccessByActionAndOperation(
        categories,
        categoriesActions.create,
        categoriesOperations.canCreate,
        'categories'
      ),
      canViewDropdownCategories: getAccessByActionAndOperation(
        categories,
        categoriesActions.dropdown,
        categoriesOperations.canView,
        'categories'
      ),
      canEditCategories: getAccessByActionAndOperation(
        categories,
        categoriesActions.edit,
        categoriesOperations.canEdit,
        'categories'
      ),
      canViewCategories: getAccessByActionAndOperation(
        categories,
        categoriesActions.view,
        categoriesOperations.canView,
        'categories'
      ),
    },

    behaviors: {
      canArchiveBehaviors: getAccessByActionAndOperation(
        behaviors,
        behaviorsActions.archive,
        behaviorsOperations.canArchive,
        'behaviors'
      ),
      canCreateBehaviors: getAccessByActionAndOperation(
        behaviors,
        behaviorsActions.create,
        behaviorsOperations.canCreate,
        'behaviors'
      ),
      canEditBehaviors: getAccessByActionAndOperation(
        behaviors,
        behaviorsActions.edit,
        behaviorsOperations.canEdit,
        'behaviors'
      ),
      canViewBehaviors: getAccessByActionAndOperation(
        behaviors,
        behaviorsActions.view,
        behaviorsOperations.canView,
        'behaviors'
      ),
    },

    conditions: {
      canArchiveConditions: getAccessByActionAndOperation(
        conditions,
        conditionsActions.archive,
        conditionsOperations.canArchive,
        'conditions'
      ),
      canCreateConditions: getAccessByActionAndOperation(
        conditions,
        conditionsActions.create,
        conditionsOperations.canCreate,
        'conditions'
      ),
      // canViewDropdownConditions: getAccessByActionAndOperation(
      //   conditions,
      //   conditionsActions.dropdown,
      //   conditionsOperations.canView
      // ),
      canEditConditions: getAccessByActionAndOperation(
        conditions,
        conditionsActions.edit,
        conditionsOperations.canEdit,
        'conditions'
      ),
      canViewConditions: getAccessByActionAndOperation(
        conditions,
        conditionsActions.view,
        conditionsOperations.canView,
        'conditions'
      ),
    },

    universalSettingsImages: {
      canEditUniversalSettingsImages: getAccessByActionAndOperation(
        universalSettingsImages,
        universalSettingsImagesActions.edit,
        universalSettingsImagesOperations.canEdit,
        'universal-settings'
      ),
      canViewUniversalSettingsImages: getAccessByActionAndOperation(
        universalSettingsImages,
        universalSettingsImagesActions.view,
        universalSettingsImagesOperations.canView,
        'universal-settings'
      ),
    },
    communities: {
      canEditCommunitiesAccess: getAccessByActionAndOperation(
        communities,
        communitiesActions.access,
        communitiesOperations.canEdit,
        'communities'
      ),
      canArchiveCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.archive,
        communitiesOperations.canArchive,
        'communities'
      ),
      canViewContactInfo: getAccessByActionAndOperation(
        communities,
        communitiesActions.contactInfo,
        communitiesOperations.canView,
        'communities'
      ),
      canCreateContactInfo: getAccessByActionAndOperation(
        communities,
        communitiesActions.contactInfo,
        communitiesOperations.canCreate,
        'communities'
      ),
      canCreateCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.create,
        communitiesOperations.canCreate,
        'communities'
      ),
      canDeleteCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.delete,
        communitiesOperations.canDelete,
        'communities'
      ),
      canViewSideBarCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.delete,
        communitiesOperations.canDelete,
        'communities'
      ),
      canEditCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.edit,
        communitiesOperations.canEdit,
        'communities'
      ),
      canDeleteEntityOfCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.edit,
        communitiesOperations.canDelete,
        'communities'
      ),
      canViewEntityDropdownCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.entityDropdown,
        communitiesOperations.canView,
        'communities'
      ),
      canViewGeographyCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.geography,
        communitiesOperations.canView,
        'communities'
      ),
      canEditGeographyCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.geography,
        communitiesOperations.canEdit,
        'communities'
      ),
      canCreateGeographyCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.geography,
        communitiesOperations.canCreate,
        'communities'
      ),
      canViewGeographyMapListCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.geographyMapList,
        communitiesOperations.canView,
        'communities'
      ),
      canCreateMemberCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.member,
        communitiesOperations.canCreate,
        'communities'
      ),
      canCreateMembersCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.members,
        communitiesOperations.canCreate,
        'communities'
      ),
      canViewObjectsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.objects,
        communitiesOperations.canView,
        'communities'
      ),
      canCreateObjectsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.objects,
        communitiesOperations.canCreate,
        'communities'
      ),
      canViewOrganizationsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.organizations,
        communitiesOperations.canView,
        'communities'
      ),
      canViewRegionsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.regions,
        communitiesOperations.canView,
        'communities'
      ),
      canCreateReuseListCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.reuseList,
        communitiesOperations.canCreate,
        'communities'
      ),
      canViewSpeciesCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.species,
        communitiesOperations.canView,
        'communities'
      ),
      canCreateSpeciesCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.species,
        communitiesOperations.canCreate,
        'communities'
      ),
      canEditTagsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.tags,
        communitiesOperations.canEdit,
        'communities'
      ),
      canViewUserAdminsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.userAdmins,
        communitiesOperations.canView,
        'communities'
      ),
      canViewUserMembersCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.userMembers,
        communitiesOperations.canView,
        'communities'
      ),
      canViewVectorLayersCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.vectorLayers,
        communitiesOperations.canView,
        'communities'
      ),
      canViewViewCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.view,
        communitiesOperations.canView,
        'communities'
      ),

      canCreateAttachRegionCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.attachRegion,
        communitiesOperations.canCreate
      ),

      canDeleteFileCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.file,
        communitiesOperations.canDelete
      ),
      canCreateFileCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.file,
        communitiesOperations.canCreate
      ),
      canDeleteMemberCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.member,
        communitiesOperations.canDelete
      ),
      canEditObjectsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.objects,
        communitiesOperations.canEdit
      ),

      canDeleteRegionsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.regions,
        communitiesOperations.canDelete
      ),
      canCreateRegionsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.regions,
        communitiesOperations.canCreate
      ),

      canViewSavedRegionsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.savedRegions,
        communitiesOperations.canView
      ),

      canEditSpeciesCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.species,
        communitiesOperations.canEdit
      ),

      canEditStatusCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.status,
        communitiesOperations.canEdit
      ),

      canDeleteTagsCommunities: getAccessByActionAndOperation(
        communities,
        communitiesActions.tags,
        communitiesOperations.canDelete
      ),
    },
    profile: {
      canEditAccountInfoProfile: getAccessByActionAndOperation(
        profile,
        profileActions.accountInfo,
        profileOperations.canEdit
      ),
      canViewAdditionalInformationProfile: getAccessByActionAndOperation(
        profile,
        profileActions.additionalInformation,
        profileOperations.canView
      ),
      canCreateAdditionalInformationProfile: getAccessByActionAndOperation(
        profile,
        profileActions.additionalInformation,
        profileOperations.canCreate
      ),
      canEditAllowNotificationProfile: getAccessByActionAndOperation(
        profile,
        profileActions.allowNotification,
        profileOperations.canEdit
      ),
      canEditAppThemeProfile: getAccessByActionAndOperation(
        profile,
        profileActions.appTheme,
        profileOperations.canEdit
      ),
      canCreateProfile: getAccessByActionAndOperation(
        profile,
        profileActions.create,
        profileOperations.canCreate
      ),
      canDeleteProfile: getAccessByActionAndOperation(
        profile,
        profileActions.delete,
        profileOperations.canDelete
      ),
      canEditDeleteAddressProfile: getAccessByActionAndOperation(
        profile,
        profileActions.deleteAddress,
        profileOperations.canEdit,
        'profile'
      ),
      canEditProfile: getAccessByActionAndOperation(
        profile,
        profileActions.edit,
        profileOperations.canEdit
      ),
      canViewNotificationProfile: getAccessByActionAndOperation(
        profile,
        profileActions.notification,
        profileOperations.canView
      ),
      canEditNotificationProfile: getAccessByActionAndOperation(
        profile,
        profileActions.notification,
        profileOperations.canEdit,
        'profile'
      ),
      canEditPrimaryEmailPhoneProfile: getAccessByActionAndOperation(
        profile,
        profileActions.primaryEmailPhone,
        profileOperations.canEdit,
        'profile'
      ),
      canViewRolesProfile: getAccessByActionAndOperation(
        profile,
        profileActions.roles,
        profileOperations.canView,
        'profile'
      ),
      canEditSecondaryEmailPhoneProfile: getAccessByActionAndOperation(
        profile,
        profileActions.secondaryEmailPhone,
        profileOperations.canEdit,
        'profile'
      ),
      canEditSettingsProfile: getAccessByActionAndOperation(
        profile,
        profileActions.settings,
        profileOperations.canEdit,
        'profile'
      ),

      canEditPasswordProfile: getAccessByActionAndOperation(
        profile,
        profileActions.password,
        profileOperations.canEdit
      ),

      canDeleteSecondaryEmailPhoneProfile: getAccessByActionAndOperation(
        profile,
        profileActions.secondaryEmailPhone,
        profileOperations.canDelete
      ),
      canViewSettingsProfile: getAccessByActionAndOperation(
        profile,
        profileActions.settings,
        profileOperations.canView
      ),

      canViewUsersQueriesProfile: getAccessByActionAndOperation(
        profile,
        profileActions.usersQueries,
        profileOperations.canView
      ),
      canCreateUsersQueriesProfile: getAccessByActionAndOperation(
        profile,
        profileActions.usersQueries,
        profileOperations.canCreate
      ),
      canViewViewProfile: getAccessByActionAndOperation(
        profile,
        profileActions.view,
        profileOperations.canView
      ),
    },
    reminders: {
      canCreateReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.create,
        remindersOperations.canCreate,
        'reminders'
      ),
      canDeleteReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.delete,
        remindersOperations.canDelete,
        'reminders'
      ),
      canEditReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.edit,
        remindersOperations.canEdit,
        'reminders'
      ),
      canViewMembersReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.members,
        remindersOperations.canView,
        'reminders'
      ),
      canDeletePersonReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.person,
        remindersOperations.canDelete,
        'reminders'
      ),
      canViewStatusHistoryReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.statusHistory,
        remindersOperations.canView,
        'reminders'
      ),
      canViewTargetMembersReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.targetMembers,
        remindersOperations.canView,
        'reminders'
      ),
      canViewViewReminders: getAccessByActionAndOperation(
        reminders,
        remindersActions.view,
        remindersOperations.canView,
        'reminders'
      ),
    },
    notifications: {
      canViewNotifications: getAccessByActionAndOperation(
        notifications,
        notificationsActions.view,
        notificationsOperations.canView,
        'notifications'
      ),
      canCreateNotifications: getAccessByActionAndOperation(
        notifications,
        notificationsActions.readAll,
        notificationsOperations.canCreate,
        'notifications'
      ),
      canReadNotifications: getAccessByActionAndOperation(
        notifications,
        notificationsActions.read,
        notificationsOperations.canEdit,
        'notifications'
      ),
    },
    surveyAndPolls: {
      canCreateSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.create,
        surveyAndPollsOperations.canCreate,
        'surveyPolls'
      ),
      canDeleteSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.delete,
        surveyAndPollsOperations.canDelete,
        'surveyPolls'
      ),
      canViewDropdownSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.dropdown,
        surveyAndPollsOperations.canView,
        'surveyPolls'
      ),
      canEditSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.edit,
        surveyAndPollsOperations.canEdit,
        'surveyPolls'
      ),
      canEditFieldsSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.edit,
        surveyAndPollsOperations.canEdit,
        'surveyPolls'
      ),
      canViewResultsSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.view,
        surveyAndPollsOperations.canView,
        'surveyPolls'
      ),
      canEditStatusSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.edit,
        surveyAndPollsOperations.canEdit,
        'surveyPolls'
      ),
      canViewVersionSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.version,
        surveyAndPollsOperations.canView,
        'surveyPolls'
      ),
      canViewSurveyAndPolls: getAccessByActionAndOperation(
        surveyAndPolls,
        surveyAndPollsActions.view,
        surveyAndPollsOperations.canView,
        'surveyPolls'
      ),
    },
    trackings: {
      canViewAdminTracking: getAccessByActionAndOperation(
        trackings,
        trackingsActions.view,
        trackingsOperations.canView,
        'notifications'
      ),
    },
    globalSearch: {
      canViewGlobalSearch: getAccessByActionAndOperation(
        globalSearch,
        globalSearchActions.view,
        globalSearchOperations.canView,
        'globalSearch'
      ),
    },
  };

  return updatedPermissions;
};

export default usePermissions;

function getAccessByActionAndOperation(
  actionsArray: any[],
  action: string,
  operation: string,
  category?: any
) {
  const alwaysTrueCategories = [
    'tags',
    'permissions',
    'roles',
    'universal-settings',
    'surveyPolls',
    'reports',
    'reminders',
    'notifications',
    'dashboard',
    'conversations',
    'geographies',
    'species',
    'objects',
    'behaviors',
    'conditions',
    'imports',
    'categories',
    'types',
    'qa',
    'observations',
    'dynamicForms',
    'protocols',
    'programs',
    'organizations',
    'file',
    'communities',
    'users',
    // "newPassword",
    // "verifyEmailPhone",
    // "detachSocialAccount",
    // "deleteAccountRequest",
    // "assignRoles",
    // "attachSocialAccount",
    // "confirmEmailPhone",
    // "globalSearch",
  ];

  if (alwaysTrueCategories.includes(category)) {
    const found = actionsArray?.find(
      (obj) => obj.action === action && obj.operation === operation
    );
    return found ? found.access : false;
  } else {
    return true;
  }
}
